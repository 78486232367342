import { Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react"
import SelectUpArrow from "../assets/selectUpArrow.svg";
import SelectDownArrow from "../assets/selectDownArrow.svg";
import UserIcon from "../assets/userIcon.svg";
import KeyIcon from "../assets/key.svg";
import LogoutIcon from "../assets/log-out.svg";
import { useNavigate } from "react-router-dom";
import UserRolesIcon from "../assets/usersRoles.svg";
import { getStoredUserPrivileges } from "../helper/utils";
import { AdminAccess } from "../utils";

const NavBar = () => {
    const [opened, handlers] = useDisclosure(false);
    const navigate = useNavigate();
    const { roleDetails } = getStoredUserPrivileges();
    const userName = roleDetails?.email?.split('@')[0];

    const handleLogout = () => {
        sessionStorage.clear()
        navigate("/login")
        sessionStorage.removeItem("Swastik_login")
        window.location.reload();
    }

    return (
        <nav className="bg-bgLightGrey  fixed w-full z-20 top-0 start-0 border-b border-gray-200 ">
            <div className=" flex flex-wrap items-center justify-between mx-auto pl-4 pr-4 pt-4 pb-4">
                <button type="button" className="inter-600-344054 bg-white  focus:outline-none   rounded-lg text-sm sm:text-base px-4 py-2 text-center border">Swastik Productions</button>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    {/* <button type="button" className="text-text-lightBlack  bg-white focus:outline-none  font-semibold rounded-lg text-sm px-4 py-2 text-center border">Welcome, Vivek(Admin)</button> */}

                    <Menu shadow="md" width={200} opened={opened} onOpen={handlers.open} onClose={handlers.close}>
                        <Menu.Target>
                            <button className="flex items-center gap-2 w-full bg-white border border-gray-300 inter-600-344054 text-xs lg:text-sm 2xl:text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-5 pr-5 pt-2 pb-2">
                                {`Welcome, ${userName}(${roleDetails?.role_name})`}
                                <img src={opened ? SelectUpArrow : SelectDownArrow} alt="dropdown" className="h-3 w-3 object-contain" />
                            </button>
                        </Menu.Target>

                        <Menu.Dropdown >
                            <div className="">
                                <Menu.Item className="">
                                    <div className="flex justify-between cursor-pointer">
                                        <div className="flex items-center" onClick={()=>navigate("/user-profile")}>
                                            <img src={UserIcon} alt="ProfileIcon" className="h-4 w-4" />
                                            <p className="ml-2 inter-500-344054 text-sm">View profile</p>
                                        </div>
                                    </div>
                                </Menu.Item>
                                {/* <Menu.Item className="">
                                    <div className="flex justify-between cursor-pointer">
                                        <div className="flex items-center" >
                                            <img src={KeyIcon} alt="ProfileIcon" className="h-4 w-4" />
                                            <p className="ml-2 text-sm inter-500-344054">Change Password</p>
                                        </div>
                                    </div>
                                </Menu.Item> */}
                                {AdminAccess(roleDetails) && <Menu.Item className="" onClick={() => navigate("/roles")}>
                                    <div className="flex justify-between cursor-pointer">
                                        <div className="flex items-center" >
                                            <img src={UserRolesIcon} alt="UserRolesIcon" className="h-4 w-4" />
                                            <p className="ml-2 text-sm inter-500-344054">User Roles</p>
                                        </div>
                                    </div>
                                </Menu.Item>}
                            </div>

                            <Menu.Divider />
                            <Menu.Item className="" onClick={handleLogout}>
                                <div className="flex justify-between cursor-pointer">
                                    <div className="flex items-center">
                                        <img src={LogoutIcon} alt="ProfileIcon" className="h-4 w-4" />
                                        <p className="ml-2  text-sm  inter-500-344054">Log out</p>
                                    </div>
                                </div>
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </div>
            </div>
        </nav>
    )
};

export default NavBar;
